<template>
  <v-form ref="form" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-caption">帳號</div>
          <div class="black--text text-body-1">{{ form.userid }}</div>
        </v-col>
        <v-col cols="12">
          <div class="text-caption">名稱</div>
          <div class="black--text text-body-1">{{ form.name }}</div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center mb-2">
            <div class="text-caption">OpenID Email</div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>每次登入更新 OpenID 系統設定的Email</span>
            </v-tooltip>
          </div>
          <div class="black--text text-body-1">
            {{ value.openIdEmail || '-' }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center mb-2">
            <div class="text-caption">OpenID 角色</div>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>由 OpenID 職稱轉換，每次登入更新</span>
            </v-tooltip>
          </div>
          <div class="black--text text-body-1">
            {{
              (value.openIdRoles &&
                value.openIdRoles.length &&
                value.openIdRoles[0].remarks) ||
              '-'
            }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center mb-2">
            <div class="text-caption">OpenID 職稱</div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>每次登入更新 OpenID 系統設定的職稱</span>
            </v-tooltip>
          </div>
          <div class="black--text text-body-1">
            {{ value.openIdTitles || '-' }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center mb-n4">
            <div class="text-caption">Email</div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>非必填，擁有帳號管理權限者可變更</span>
            </v-tooltip>
          </div>
          <v-text-field
            v-model="form.email"
            :rules="emailRules"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center mb-n4">
            <div class="text-caption">角色</div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>非必填，擁有帳號管理權限者可變更</span>
            </v-tooltip>
          </div>
          <v-select
            :items="schoolRolesOption"
            v-model="selectedRole"
            item-text="remarks"
            item-value="id"
            return-object
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" v-if="needClassId">
          <v-select
            :items="classOption"
            label="班級"
            v-model="selectedClass"
            item-text="name"
            return-object
            :rules="[(v) => !!v || '必填']"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center mb-2">
            <div class="text-caption">實際角色</div>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span
                >系統判定角色方式優先以角色為主，角色未設定則以 OpenID
                角色為主</span
              >
            </v-tooltip>
          </div>
          <div class="black--text text-body-1">
            {{
              (form.roles && form.roles.length && form.roles[0].remarks) ||
              (value.openIdRoles.length && value.openIdRoles[0].remarks) ||
              '-'
            }}
          </div>
        </v-col>
      </v-row>
      <v-row align-content="end">
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn color="primary" outlined @click="cancel"> 取消 </v-btn>
        </v-col>
        <v-col cols="auto" v-if="value && value.id">
          <v-btn color="primary" elevation="0" @click="submit"> 修改 </v-btn>
        </v-col>
        <v-col cols="auto" v-else>
          <v-btn color="primary" elevation="0" @click="submit"> 新增 </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="value && value.id && !ownAccount"
        class="ems-light rounded mt-2"
        align-content="space-between"
        align="center"
      >
        <v-col>
          <span class="accent--text">刪除後將無法再使用。</span>
        </v-col>
        <v-col cols="auto">
          <v-btn outlined color="error" @click="deleteAccount"
            >刪除此帳號</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
const findClass = (id, tree) => {
  const obj = tree.find((d) => d.id === id)
  if (obj) {
    return obj.branch
  }
  if (!obj) {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].branch) {
        const found = findClass(id, tree[i].branch)
        if (found) {
          return found
        }
        break
      }
    }
  }
}

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        id: null,
        email: null,
        name: null,
        roles: [],
        classroomId: null
      })
    }
  },
  data: () => ({
    form: {
      email: null,
      name: null,
      roles: [],
      classroomId: null,
      userid: null
    },
    selectedRole: null,
    selectedClass: null,
    emailRules: [
      (value) => {
        if (value) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-mail 格式有誤'
        }
        return true
      }
    ]
  }),
  watch: {
    selectedRole() {
      if (this.selectedRole) {
        this.form.roles = [this.selectedRole]
        if (this.selectedRole.name !== 'CLASS_USER')
          this.form.classroomId = null
      } else {
        this.form.roles = []
      }
    },
    selectedClass() {
      if (this.selectedClass) {
        this.form.classroomId = this.selectedClass.id
      } else {
        this.form.classroomId = null
      }
    }
  },
  computed: {
    ...mapGetters({
      tags: 'page/tags',
      rolesOption: 'role/schoolRolesOption',
      userData: 'user/userData'
    }),
    schoolRolesOption() {
      if (!this.rolesOption) {
        return []
      }
      return this.rolesOption.filter((role) =>
        ['SCH_USER', 'CLASS_USER', 'SCH_ADMIN', 'SCH_AFFAIRS'].includes(
          role.name
        )
      )
    },
    tagId() {
      return this.$route.params.tagId || ''
    },
    classOption() {
      if (!this.tags || !this.tagId) return []
      return findClass(this.tagId, [this.tags])
    },
    needClassId() {
      return ['CLASS_USER'].includes(this.selectedRole?.name)
    },
    ownAccount() {
      return this.value.userid === this.userData.user_name
    }
  },
  methods: {
    ...mapActions({
      getSchoolRolesOption: 'role/getSchoolRolesOption'
    }),
    resetForm() {
      this.form = {
        email: null,
        name: null,
        roles: [],
        classroomId: null
      }
      this.selectedRole = null
      this.selectedClass = null
    },
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('input', this.form)
        this.$emit('submit')
      }
    },
    deleteAccount() {
      this.$emit('delete', this.form)
    }
  },
  mounted() {
    document.getElementsByTagName('input').forEach((element) => {
      element.setAttribute('autocomplete', 'off')
    })
    if (!this.schoolRolesOption) {
      this.getSchoolRolesOption()
    }
    if (this.value) {
      this.form = {
        id: this.value.id,
        name: this.value.name,
        roles: this.value.roles,
        classroomId: this.value.classroomId,
        userid: this.value.userid,
        email: this.value.email
      }
      this.selectedRole = this.value.roles.length && this.value.roles[0]
      if (this.value.classroomId) {
        this.selectedClass = this.classOption.find(
          (d) => d.id === this.value.classroomId
        )
      }
    }
  }
}
</script>
