var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-3"},[_c('v-card',{staticClass:"mx-auto pa-3 ems-opacity-50-bg frosted-glass",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"px-2 headline accent--text d-flex justify-space-between"},[_c('span',[_vm._v(" 帳號管理 ")]),_c('span',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between",attrs:{"primary-title":""}},[(_vm.accountData && _vm.accountData.id)?_c('span',[_vm._v(" 修改使用者 ")]):_c('span',[_vm._v(" 新增使用者 ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[(_vm.dialog)?_c('CreateForm',{on:{"cancel":function($event){_vm.dialog = false},"delete":_vm.deleteAccount,"submit":_vm.handleAccount},model:{value:(_vm.accountData),callback:function ($$v) {_vm.accountData=$$v},expression:"accountData"}}):_vm._e()],1)],1)],1)],1)]),_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.list.headers,"items":_vm.list.data || [],"items-per-page":_vm.list.perPage,"loading":_vm.list.loading,"loading-text":"Loading...","no-data-text":"查無資料","footer-props":{
            'items-per-page-text': '每頁'
          }},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.roles.map(function (ref) {
                    var remarks = ref.remarks;

                    return remarks;
          }).join())+" ")]}},{key:"item.openIdRoles",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.openIdRoles.map(function (ref) {
                    var remarks = ref.remarks;

                    return remarks;
          }).join())+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(!_vm.ownAccount(item))?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }