var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-caption"},[_vm._v("帳號")]),_c('div',{staticClass:"black--text text-body-1"},[_vm._v(_vm._s(_vm.form.userid))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-caption"},[_vm._v("名稱")]),_c('div',{staticClass:"black--text text-body-1"},[_vm._v(_vm._s(_vm.form.name))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-caption"},[_vm._v("OpenID Email")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("每次登入更新 OpenID 系統設定的Email")])])],1),_c('div',{staticClass:"black--text text-body-1"},[_vm._v(" "+_vm._s(_vm.value.openIdEmail || '-')+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-caption"},[_vm._v("OpenID 角色")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("由 OpenID 職稱轉換，每次登入更新")])])],1),_c('div',{staticClass:"black--text text-body-1"},[_vm._v(" "+_vm._s((_vm.value.openIdRoles && _vm.value.openIdRoles.length && _vm.value.openIdRoles[0].remarks) || '-')+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-caption"},[_vm._v("OpenID 職稱")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("每次登入更新 OpenID 系統設定的職稱")])])],1),_c('div',{staticClass:"black--text text-body-1"},[_vm._v(" "+_vm._s(_vm.value.openIdTitles || '-')+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-n4"},[_c('div',{staticClass:"text-caption"},[_vm._v("Email")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("非必填，擁有帳號管理權限者可變更")])])],1),_c('v-text-field',{attrs:{"rules":_vm.emailRules,"clearable":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-n4"},[_c('div',{staticClass:"text-caption"},[_vm._v("角色")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("非必填，擁有帳號管理權限者可變更")])])],1),_c('v-select',{attrs:{"items":_vm.schoolRolesOption,"item-text":"remarks","item-value":"id","return-object":"","clearable":""},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1),(_vm.needClassId)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.classOption,"label":"班級","item-text":"name","return-object":"","rules":[function (v) { return !!v || '必填'; }]},model:{value:(_vm.selectedClass),callback:function ($$v) {_vm.selectedClass=$$v},expression:"selectedClass"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"text-caption"},[_vm._v("實際角色")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("系統判定角色方式優先以角色為主，角色未設定則以 OpenID 角色為主")])])],1),_c('div',{staticClass:"black--text text-body-1"},[_vm._v(" "+_vm._s((_vm.form.roles && _vm.form.roles.length && _vm.form.roles[0].remarks) || (_vm.value.openIdRoles.length && _vm.value.openIdRoles[0].remarks) || '-')+" ")])])],1),_c('v-row',{attrs:{"align-content":"end"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")])],1),(_vm.value && _vm.value.id)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.submit}},[_vm._v(" 修改 ")])],1):_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.submit}},[_vm._v(" 新增 ")])],1)],1),(_vm.value && _vm.value.id && !_vm.ownAccount)?_c('v-row',{staticClass:"ems-light rounded mt-2",attrs:{"align-content":"space-between","align":"center"}},[_c('v-col',[_c('span',{staticClass:"accent--text"},[_vm._v("刪除後將無法再使用。")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.deleteAccount}},[_vm._v("刪除此帳號")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }