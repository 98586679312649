<template>
  <div>
    <div class="pa-3">
      <v-card
        class="mx-auto pa-3 ems-opacity-50-bg frosted-glass"
        elevation="0"
      >
        <!-- v-if="infoData" -->
        <v-card-title
          class="px-2 headline accent--text d-flex justify-space-between"
        >
          <span> 帳號管理 </span>
          <span>
            <v-dialog v-model="dialog" max-width="500px">
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" elevation="0">
                  新增使用者綁定
                </v-btn>
              </template> -->
              <v-card>
                <v-card-title
                  primary-title
                  class="d-flex justify-space-between"
                >
                  <span v-if="accountData && accountData.id"> 修改使用者 </span>
                  <span v-else> 新增使用者 </span>
                  <v-btn icon @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <CreateForm
                    v-if="dialog"
                    v-model="accountData"
                    @cancel="dialog = false"
                    @delete="deleteAccount"
                    @submit="handleAccount"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
          </span>
        </v-card-title>
        <div>
          <v-data-table
            :headers="list.headers"
            :items="list.data || []"
            :items-per-page="list.perPage"
            :loading="list.loading"
            loading-text="Loading..."
            no-data-text="查無資料"
            :footer-props="{
              'items-per-page-text': '每頁'
            }"
            class="elevation-0"
          >
            <template v-slot:[`item.roles`]="{ item }">
              {{ item.roles.map(({ remarks }) => remarks).join() }}
            </template>
            <template v-slot:[`item.openIdRoles`]="{ item }">
              {{ item.openIdRoles.map(({ remarks }) => remarks).join() }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon
                class="mr-2"
                @click="editItem(item)"
                v-if="!ownAccount(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <!-- <v-card
        class="mx-auto pa-3 ems-opacity-50-bg frosted-glass mt-4"
        elevation="0"
      >
        <v-card-title
          class="px-2 headline accent--text d-flex justify-space-between"
        >
          管理者建立審核清單
        </v-card-title>
        <div>
          <v-data-table
            :headers="beReviewedList.headers"
            :items="beReviewedList.data || []"
            :items-per-page="beReviewedList.perPage"
            :loading="beReviewedList.loading"
            loading-text="Loading..."
            no-data-text="查無資料"
            :footer-props="{
              'items-per-page-text': '每頁'
            }"
            class="elevation-0"
          >
            <template v-slot:[`item.targetAccountName`]="{ item }">
              {{ item.targetAccount.name }}
            </template>
            <template v-slot:[`item.auditDate`]="{ item }">
              <div>
                {{
                  item.auditDate
                    ? moment(item.auditDate).format('YYYY-MM-DD HH:mm:ss')
                    : ''
                }}
              </div>
            </template>
            <template v-slot:[`item.auditResult`]="{ item }">
              <div
                v-if="displayStatus(item).color === 'grey'"
                class="grey--text"
              >
                {{ displayStatus(item).text }}
              </div>
              <v-chip
                v-else-if="displayStatus(item)"
                :color="displayStatus(item).color"
                >{{ displayStatus(item).text }}</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="!item.auditDate"
                :disabled="!ltCurrent(item.sendTs)"
                color="primary"
                elevation="0"
                >{{
                  ltCurrent(item.sendTs) ? '重發核可確認信' : `05:00 後可再重發`
                }}</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Account from '@/api/Account'
import School from '@/api/admin/School'
import CreateForm from '../../components/Account/create.vue'
import moment from 'moment'

export default {
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  components: {
    CreateForm
  },
  data() {
    return {
      list: {
        headers: [
          {
            text: '名稱',
            value: 'name',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: '帳號',
            value: 'userid',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: 'OpenID Email',
            value: 'openIdEmail',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: 'OpenID 角色',
            value: 'openIdRoles',
            class: 'blue lighten-4 text-subtitle-2',
            sort: (a, b) => {
              return a
                .map(({ remarks }) => remarks)
                .join()
                .localeCompare(b.map(({ remarks }) => remarks).join())
            }
          },
          {
            text: 'OpenID 職稱',
            value: 'openIdTitles',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: 'email',
            value: 'email',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: '角色',
            value: 'roles',
            class: 'blue lighten-4 text-subtitle-2',
            sort: (a, b) => {
              return a
                .map(({ remarks }) => remarks)
                .join()
                .localeCompare(b.map(({ remarks }) => remarks).join())
            }
          },

          {
            text: '建立時間',
            value: 'createdDate',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: '',
            value: 'action',
            class: 'blue lighten-4 text-subtitle-2',
            sortable: false
          }
        ],
        loading: true,
        data: null,
        perPage: 10,
        currentPage: 1
      },
      dialog: false,
      accountData: {},
      roles: []
      // beReviewedList: {
      //   headers: [
      //     {
      //       text: '名稱',
      //       value: 'targetAccountName',
      //       class: 'blue lighten-4 text-subtitle-2'
      //     },
      //     {
      //       text: '完成審核時間',
      //       value: 'auditDate',
      //       class: 'blue lighten-4 text-subtitle-2'
      //     },
      //     {
      //       text: '審核狀態',
      //       value: 'auditResult',
      //       class: 'blue lighten-4 text-subtitle-2'
      //     }
      //     // {
      //     //   value: 'action',
      //     //   class: 'blue lighten-4 text-subtitle-2',
      //     //   align: 'right'
      //     // }
      //   ],
      //   loading: true,
      //   data: null,
      //   perPage: 10,
      //   currentPage: 1
      // }
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.accountData = {}
      }
    }
  },
  computed: {
    ...mapGetters({
      schoolRolesOption: 'role/schoolRolesOption',
      userData: 'user/userData'
    })
  },
  mounted() {
    this.init()
    if (!this.schoolRolesOption) {
      this.getRoles()
    }
  },
  methods: {
    ...mapActions({
      getRoles: 'role/getSchoolRolesOption',
      showMsg: 'snackbar/show'
    }),
    init() {
      this.getList()
      // this.getBeReviewedList()
    },
    async getList() {
      this.list.loading = true
      try {
        const { data } = await Account.list()
        this.list.data = data || []
        // .map(({ roles, ...rest }) => {
        //   return {
        //     ...rest,
        //     role: roles ? roles.map((r) => r.remarks).join(', ') : ''
        //   }
        // })
      } catch (error) {
        console.error(error)
      } finally {
        this.list.loading = false
      }
    },
    async handleAccount() {
      if (!this.accountData) return
      if (this.accountData.id) {
        this.editAccount()
        return
      }
      try {
        await Account.create(this.accountData)
      } catch (error) {
        console.error(error)
        this.showMsg({
          message: error.response.data.errorMessage || '新增失敗',
          color: 'error',
          closable: true,
          time: 3000
        })
      } finally {
        this.init()
        this.dialog = false
        this.accountData = {}
      }
    },
    async editAccount() {
      try {
        await Account.update(this.accountData.id, this.accountData)
      } catch (error) {
        console.error(error)
        this.showMsg({
          message: error.response.data.errorMessage || '修改失敗',
          color: 'error',
          closable: true,
          time: 3000
        })
      } finally {
        this.init()
        this.dialog = false
        this.accountData = {}
      }
    },
    editItem(item) {
      this.accountData = item
      this.dialog = true
    },
    async deleteAccount(item) {
      if (!item.id) {
        this.init()
        this.dialog = false
        this.accountData = {}
        return
      }
      try {
        await Account.delete(item.id)
      } catch (error) {
        console.error(error)
      } finally {
        this.init()
        this.dialog = false
        this.accountData = {}
      }
    },
    // async getBeReviewedList() {
    //   this.beReviewedList.loading = true
    //   try {
    //     const { data } = await School.listPermissionChange(
    //       this.$route.params.tagId
    //     )
    //     this.beReviewedList.data = Array.isArray(data)
    //       ? data.sort((a, b) => b.seq - a.seq)
    //       : data
    //   } catch (e) {}
    //   this.beReviewedList.loading = false
    // },
    // displayStatus(item) {
    //   if (item) {
    //     switch (item.auditResult) {
    //       case 0:
    //         return moment().isAfter(moment(item.expireDate))
    //           ? { text: '逾期', color: 'grey' }
    //           : { text: '待審核', color: 'warning' }
    //       case 1:
    //         return { text: '核可', color: 'success' }
    //       case 2:
    //         return { text: '否決', color: 'grey' }
    //       default:
    //         return null
    //     }
    //   }
    //   return null
    // },
    // ltCurrent(sendTime) {
    //   const current = new Date()
    //   // console.log(moment(sendTime).isBefore(current), moment(sendTime))
    //   return moment(sendTime).isBefore(current)
    // },
    // diffTime(sendTime) {
    //   let diffSeconds = moment(sendTime).diff(moment())
    //   return moment.utc(diffSeconds).format('HH:mm:ss')
    // },
    ownAccount(target) {
      return target.name === this.userData.user_name
    }
  }
}
</script>

<style></style>
